import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"height":"1200px"},attrs:{"width":"500px","value":_vm.isActive},on:{"click:outside":function($event){return _vm.close()},"input":function (val) { return _vm.$emit('update:is-active', val); }}},[_c(VCard,{staticClass:"transition-fast-in-fast-out",staticStyle:{"height":"100%"}},[_c(VCardTitle,{staticClass:"text-h5 lighten-2"},[_vm._v(" Complain Reply ")]),_c(VDivider,{staticClass:"mt-2 mb-4"}),(_vm.AdminReply != null && _vm.AdminReply != '')?_c(VCardText,{staticClass:"pt-6"},[_vm._v(" "+_vm._s(_vm.AdminReply)+" ")]):_c(VCardText,{staticClass:"pt-6 pb-3"},[_vm._v(" No reply yet, please contact your manager. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Done! ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }