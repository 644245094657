import request from '@core/utils/RequestApi'
import qs from 'qs'

export function Create(data) {
  return request({
    url: '/Complain/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}

export function Edit(data) {
  return request({
    url: '/Complain/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Delete(data) {
  return request({
    url: '/Complain/Delete',
    method: 'post',
    data: qs.stringify(data),
  })
}

export function GetByBranchId(data) {
  return request({
    url: '/Complain/GetByBranchId',
    method: 'get',
    params: data,
  })
}
export function GetForAgent(data) {
  return request({
    url: '/Complain/GetForAgent',
    method: 'get',
    params: data,
  })
}
export function GetForAdmin(data) {
  return request({
    url: '/Complain/GetForAdmin',
    method: 'get',
    params: data,
  })
}
export function GetForOwner(data) {
  return request({
    url: '/Complain/GetForOwner',
    method: 'get',
    params: data,
  })
}
export function GetComplainByBranch(data) {
  return request({
    url: '/Complain/GetComplainByBranch',
    method: 'get',
    params: data,
  })
}
export function CreateReply(data) {
  return request({
    url: '/Complain/CreateReply',
    method: 'post',
    data: qs.stringify(data),
  })
}
