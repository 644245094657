<template>
  <v-row>
    <v-col cols="12">
    <reply-dialog
      @refetch-data="fetchOrders"
      v-model="isActive"
      :AdminReply="complainTemp.AdminReply"

    ></reply-dialog>

      <!-- Main Card -->
      <app-card-actions @refresh="fetchOrders">
        <template slot="title"> Orders List </template>
        <!-- search -->


        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="ComplainListTable"
          :items-per-page="10"
          :options.sync="options"
          :loading="loading"
          :sort-desc="[true]"
        >
          <!-- id -->
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>

          <!-- Name -->
          <template #[`item.ComplainCategory`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.ComplainCategory }}</span>
              </div>
            </div>
          </template>
          <!-- CreationTime -->
          <template #[`item.CreationTime`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{
                  filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
                }}</span>
              </div>
            </div>
          </template>

               <!-- complain -->
      <template #[`item.AdminReply`]="{ item }">

          <v-btn
            icon
            v-bind:color="item.AdminReply !='' && item.AdminReply != null ? 'info' : 'secondary'"
            @click.stop=";(isActive = !isActive), (complainTemp = { ...item })"
          >
            <v-icon >
              {{ icons.mdiCommentFlash }}
            </v-icon>
          </v-btn>
      </template>
        </v-data-table>
      </app-card-actions>
    </v-col></v-row
  >
</template>

<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import { parseTime } from '@core/utils/filter'
import { mdiCalendarRange, mdiClockOutline, mdiCommentFlash, mdiDeleteOutline } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import ReplyDialog from '../Components/ReplyDialog.vue'
import useOrderList from './useOrderList'

// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: { AppCardActions, ReplyDialog },

  setup() {
    const orderTemp = ref({ Id: 0 })
    const isActive = ref(false)
    const complainTemp = ref({ AdminReply: '' })
    const isDialogDelete = ref(false)
    const {
      fetchOrders,
      OrderListTable,
      ComplainListTable,
      tableColumns,
      options,
      totalOrderListTable,
      loading,
      expanded,
      resolveTotalIcon,
    } = useOrderList()
    onMounted(() => {
       fetchOrders()
    })
    return {
      isActive,
      complainTemp,
      isDialogDelete,
      orderTemp,
      tableColumns,
      options,
      totalOrderListTable,
      OrderListTable,
      ComplainListTable,
      loading,
      expanded,
      fetchOrders,
      resolveTotalIcon,
      filter: { parseTime },
      icons: {
        mdiClockOutline,
        mdiCalendarRange,
        mdiDeleteOutline,
        mdiCommentFlash,
      },
    }
  },
}
</script>
