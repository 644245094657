<template>
  <v-dialog @click:outside="close()" style="height: 1200px" width="500px" :value="isActive" @input="val => $emit('update:is-active', val)">
  <v-card class="transition-fast-in-fast-out" style="height: 100%">
 <v-card-title class="text-h5 lighten-2">
          Complain Reply
        </v-card-title>
        <v-divider class="mt-2 mb-4"></v-divider>
    <v-card-text class="pt-6" v-if="AdminReply != null && AdminReply != ''">
         {{AdminReply}}
    </v-card-text>
       <v-card-text class="pt-6 pb-3" v-else>
         No reply yet, please contact your manager.
    </v-card-text>
    <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close()"
          >
            Done!
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'isActive',
    event: 'update:is-active',
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    AdminReply: {
      default: 'No reply yet, please contact your manager',
      type: String,
    },
    
  },

  setup(props, { emit }) {
     const close = () => {
      emit('update:is-active', false)
    }
    return {
      close,
    }
  },
}
</script>
