import { GetForAgent } from '@core/api/Complain'
import { DateTimeFormatter, Instant, LocalDateTime } from '@js-joda/core'
import { mdiCart, mdiCurrencyUsd } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default function useOrderList() {
  // Table Handlers
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Complain Category', value: 'ComplainCategory' },
    { text: 'Comment', value: 'Comment' },
    { text: 'BranchNumber', value: 'BranchNumber' },
    { text: 'RestaurantNumber', value: 'RestaurantNumber' },
    { text: 'PhoneNumber', value: 'PhoneNumber' },
    { text: 'Creation Time', value: 'CreationTime' },
    { text: 'AdminReply', value: 'AdminReply', sortable: false },
  ]
  const OrderListTable = ref([])
  const ComplainListTable = ref([])
  const expanded = ref([])
  const searchDateFrom = ref(new Date())
  const searchDateTo = ref(new Date())
  const totalOrderListTable = ref(0)
  searchDateFrom.value.setHours(0, 0, 0, 0)
  searchDateTo.value.setHours(23, 59, 59, 999)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const loading = ref(false)

  const fetchOrders = () => {
    GetForAgent({
      DateFrom: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateFrom.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
      ),
      DateTo: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateTo.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
      ),
    })
      .then(response => {
        // handle success
        OrderListTable.value = response
        ComplainListTable.value = response
        //totalOrderListTable.value = response.length
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        loading.value = false

        console.log(error)
      })
  }
  const resolveTotalIcon = total => {
    if (total === 'Count') return { icon: mdiCart, color: 'primary' }
    if (total === 'Amount') return { icon: mdiCurrencyUsd, color: 'success' }

    return { icon: mdiCart, color: 'primary' }
  }
  return {
    fetchOrders,
    ComplainListTable,
    expanded,
    tableColumns,
    options,
    OrderListTable,
    totalOrderListTable,
    loading,
    searchDateFrom,
    searchDateTo,
    resolveTotalIcon,
  }
}
